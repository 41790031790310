import { useState } from "react";

import { AC_STEP_CONFIRM_AGE, AC_STEP_VALIDATE_EMAIL, AC_STEP_FORGOT_USERNAME } from "../../utils/AccountCreationConstants";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";

import useCrossUINavigation from "../../../../../../../common/state/crossUINavigation/UseCrossUINavigation";

import { evaluateBirthDate } from "../../../../../../../common/utils/validation";

const INITIAL_INVALID_EMAIL_POPUP_STATE = {
  displayPopup: false,
  dupEmail: '',
  submittedEmail: ''
};

const INITIAL_API_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const MINIMUM_ACCOUNT_AGE = 16;

const useAccountCreationUserInfo = () => {
  const { navigateToLandingPage } = useCrossUINavigation();
  const { accountCreationState, accountCreationStep1State, postAccountCreationStep1,
    updateAccountCreationStep, updateAccountCreationPageData, resetAccountCreationState, resetAccountCreationStep1State
  } = useAccountCreationData();
  const [displayInvalidAgePopupState, setDisplayInvalidAgePopupState] = useState(false);
  const [invalidEmailPopupState, setInvalidEmailPopupState] = useState(INITIAL_INVALID_EMAIL_POPUP_STATE);
  const [apiErrorPopupState, setAPIErrorPopupState] = useState(INITIAL_API_ERROR_POPUP_STATE);

  const onBackClicked = (formState) => {
    if (accountCreationState.childData) {
      navigateToLandingPage();
    } else {
      updateAccountCreationStep(AC_STEP_CONFIRM_AGE, formState, 'userInfoData');
    }
  };

  const onReturnToLoginClicked = () => {
    resetAccountCreationState();
  };

  const onDupCheckPopupForgotUsernameClicked = () => {
    resetAccountCreationStep1State();
    updateAccountCreationStep(AC_STEP_FORGOT_USERNAME);
  };

  const onDupCheckPopupCancelClicked = () => {
    resetAccountCreationStep1State();
  };

  const onCloseInvalidEmailPopupClicked = () => {
    resetAccountCreationStep1State();
    setInvalidEmailPopupState(INITIAL_INVALID_EMAIL_POPUP_STATE);
  };

  const onCloseAPIErrorPopupClicked = () => {
    resetAccountCreationStep1State();
    setAPIErrorPopupState(INITIAL_API_ERROR_POPUP_STATE);
  };

  const onDupCheckPopupContinueClicked = (personId = undefined) => {
    if (personId) {
      if (accountCreationStep1State.objData.matches?.length > 0) {
        const targetDup = accountCreationStep1State.objData.matches.find(x => x.personId === personId);

        // to match with a person, entered email must match existing person email
        if (targetDup?.personInfo?.personEmail === '' || doEmailsMatch(targetDup?.personInfo?.personEmail, accountCreationState.userInfoData?.email)) {
          const stepObj = createStepObj(accountCreationState.userInfoData);

          const postAccountCreationStep1Promise = postAccountCreationStep1(stepObj, true);

          if (postAccountCreationStep1Promise ?? false) {
            postAccountCreationStep1Promise.then((newState) => {
              if (newState ?? false) {
                if (newState.objData.errorMessage === null) {
                  // if verificationCode is not null a validation email was sent, navigate to next view
                  if (newState.objData.verificationCode !== null) {
                    updateAccountCreationStep(AC_STEP_VALIDATE_EMAIL, { ...accountCreationState.userInfoData, personId: targetDup.personId, verificationCode: newState.objData.verificationCode }, 'userInfoData');
                  } else {
                    setAPIErrorPopupState({
                      ...apiErrorPopupState,
                      displayPopup: true,
                      errorMessage: null
                    });
                  }
                } else {
                  setAPIErrorPopupState({
                    ...apiErrorPopupState,
                    displayPopup: true,
                    errorMessage: newState.objData.errorMessage
                  });
                }
              }
            }).catch((e) => {
              setAPIErrorPopupState({
                ...apiErrorPopupState,
                displayPopup: true,
                errorMessage: e?.message
              });
            });
          }
        } else {
          setInvalidEmailPopupState({
            ...invalidEmailPopupState,
            displayPopup: true,
            dupEmail: targetDup.personInfo.personEmail,
            submittedEmail: accountCreationState.userInfoData.email
          });
        }
      } else {
        setAPIErrorPopupState({
          ...apiErrorPopupState,
          displayPopup: true,
          errorMessage: null
        });
      }
    } else {
      const stepObj = createStepObj(accountCreationState.userInfoData);

      const postAccountCreationStep1Promise = postAccountCreationStep1(stepObj, true);

      if (postAccountCreationStep1Promise ?? false) {
        postAccountCreationStep1Promise.then((newState) => {
          if (newState ?? false) {
            if (newState.objData.errorMessage === null) {
              // if verificationCode is not null a validation email was sent, navigate to next view
              if (newState.objData.verificationCode !== null) {
                updateAccountCreationStep(AC_STEP_VALIDATE_EMAIL, { ...accountCreationState.userInfoData, verificationCode: newState.objData.verificationCode }, 'userInfoData');
              } else {
                setAPIErrorPopupState({
                  ...apiErrorPopupState,
                  displayPopup: true,
                  errorMessage: null
                });
              }
            } else {
              setAPIErrorPopupState({
                ...apiErrorPopupState,
                displayPopup: true,
                errorMessage: newState.objData.errorMessage
              });
            }
          }
        }).catch((e) => {
          setAPIErrorPopupState({
            ...apiErrorPopupState,
            displayPopup: true,
            errorMessage: e?.message
          });
        });
      }
    }
  };

  const onSubmitFormCallback = (formState) => {
    // If memberId is provided do not validate minimum account age
    if (formState.memberId || evaluateBirthDate(formState.birthDate)?.ageInYears >= MINIMUM_ACCOUNT_AGE) {
      const stepObj = createStepObj(formState);

      // save formState
      updateAccountCreationPageData(formState, 'userInfoData');

      const postAccountCreationStep1Promise = postAccountCreationStep1(stepObj, false);

      if (postAccountCreationStep1Promise ?? false) {
        postAccountCreationStep1Promise.then((newState) => {
          if (newState ?? false) {
            if (newState.objData.errorMessage === null) {
              // if verificationCode is not null a validation email was sent, navigate to next view
              if (newState.objData.verificationCode !== null) {
                updateAccountCreationStep(AC_STEP_VALIDATE_EMAIL, { ...formState, verificationCode: newState.objData.verificationCode }, 'userInfoData');
              }
            } else {
              setAPIErrorPopupState({
                ...apiErrorPopupState,
                displayPopup: true,
                errorMessage: newState.objData.errorMessage
              });
            }
          }
        }).catch((e) => {
          setAPIErrorPopupState({
            ...apiErrorPopupState,
            displayPopup: true,
            errorMessage: e?.message
          });
        });
      }
    } else {
      setDisplayInvalidAgePopupState(true);
    }
  };

  function createStepObj(formState) {
    return {
      firstName: formState.firstName,
      preferredName: formState.preferredName || '',
      middleName: formState.middleName || '',
      lastName: formState.lastName,
      emailAddress: formState.email,
      birthDate: formState.birthDate,
      memberId: formState.memberId || '',
      password: formState.password,
      confirmPassword: formState.confirmPassword
    };
  }

  function doEmailsMatch(existingEmail, enteredEmail) {
    try {
      return existingEmail.toLowerCase() === enteredEmail.toLowerCase();
    } catch (e) {
      return false;
    }
  }

  return {
    isLoading: accountCreationStep1State.isObjLoading,
    userInfoData: accountCreationState?.userInfoData || {},
    childData: accountCreationState.childData,
    invalidEmailPopupState,
    displayInvalidAgePopupState,
    apiErrorPopupState,
    dupCheckState: accountCreationStep1State,
    onBackClicked,
    onReturnToLoginClicked,
    onDupCheckPopupCancelClicked,
    onDupCheckPopupForgotUsernameClicked,
    onDupCheckPopupContinueClicked,
    onCloseInvalidEmailPopupClicked,
    onCloseAPIErrorPopupClicked,
    onSubmitFormCallback
  };
};

export default useAccountCreationUserInfo;