import { useEffect, useState } from "react";

import useProfilePhotoData from '../../state/landingPages/profilePhoto/UseProfilePhotoData';

const useProfilePic = (initials, profilePicUrl) => {
  const [profilePicState, setProfilePicState] = useState('');
  const { getProfilePhotoByFileUrl} = useProfilePhotoData();

  const getFillColor = () => {
    const val = !initials
      ? 128
      : initials.length === 1
        ? 64 + initials.charCodeAt(0)
        : initials.charCodeAt(0) + initials.charCodeAt(1);
    if (val < 130) {
      return '#884EA0';
    } else if (val < 140) {
      return '#2471A3';
    } else if (val < 145) {
      return '#17A589';
    } else if (val < 150) {
      return '#D4AC0D';
    } else if (val < 155) {
      return '#CA6F1E';
    } else if (val < 160) {
      return '#2E4053';
    } else if (val < 165) {
      return '#78281F';
    } else if (val < 170) {
      return '#154360';
    } else {
      return '#186A3B';
    }
  };

  useEffect(() => {
    async function loadProfilePic() {
      if (profilePicUrl) {
        const getProfilePicResult = await getProfilePhotoByFileUrl(profilePicUrl);

        if (getProfilePicResult) {
          setProfilePicState(getProfilePicResult.objData?.downloadUrl || '');
        }
      } else {
        setProfilePicState('');
      }
    }
    loadProfilePic();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePicUrl]);

  return {
    fillColor: getFillColor(),
    profilePic: profilePicState
  };
};

export default useProfilePic;