import { useEffect, useState } from 'react';

import usePersonPaymentsData from '../../../../state/landingPages/personPayments/UsePersonPaymentsData';
import useLandingPageData from '../../../../state/landingPages/UseLandingPageData';

import usePaymentsData from '../../../../../common/state/payments/UsePaymentsData';

const INITIAL_STATE = {
  payments: [],
  isReceiptError: false,
  isLoading: true,
  showMore: true
};

const useMyPayments = () => {
  const { landingPageState } = useLandingPageData();
  const { personPaymentsState, getPersonPayments } = usePersonPaymentsData();
  const { paymentReceiptState, getPaymentReceipt, resetPaymentsReceiptState } = usePaymentsData();
  const [state, setState] = useState(INITIAL_STATE);

  const onViewReceiptClicked = (e, payment) => {
    e?.preventDefault();

    showReceiptFile(payment.internalReceiptNumber)
  };

  const onReceiptErrorClosed = () => {
    resetPaymentsReceiptState();
    setState({
      ...state,
      isReceiptError: false
    });
  };

  const onShowMore = () => {
    if (state.showMore === true) {
      setState({
        ...state,
        payments: personPaymentsState.arrayData,
        isLoading: false,
        showMore: false
      });
    } else {
      setState({
        ...state,
        payments: personPaymentsState.arrayData.slice(0, 5),
        isLoading: false,
        showMore: true
      });
    }
  }

  useEffect(() => {
    if (landingPageState.currentPerson?.personId && personPaymentsState.isArrayLoading === false && personPaymentsState.isArrayLoaded === false) {
      getPersonPayments(landingPageState.currentPerson?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageState.currentPerson?.personId]);

  useEffect(() => {
    if (personPaymentsState.isArrayLoading === false && personPaymentsState.isArrayLoaded === true) {
      setState({
        ...state,
        payments: personPaymentsState.arrayData.slice(0, 5),
        isLoading: false,
        showMore: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPaymentsState]);

  async function showReceiptFile(paymentReceiptNumber) {
    if (paymentReceiptNumber) {
      const getPaymentReceiptPromise = getPaymentReceipt(paymentReceiptNumber);

      if (getPaymentReceiptPromise ?? false) {
        getPaymentReceiptPromise.then((newState) => {
          if (newState ?? false) {
            var file = window.URL.createObjectURL(newState.objData?.blobFile);
            const link = document.createElement('a');

            link.href = file;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
        }).catch(() => {
          setState({
            ...state,
            isReceiptError: true
          });
        });
      }
    }
  }

  return {
    ...state,
    isReceiptLoading: paymentReceiptState.isObjLoading,
    personPaymentsState,
    onViewReceiptClicked,
    onShowMore,
    onReceiptErrorClosed
  };
};

export default useMyPayments;