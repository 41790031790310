import { Fragment } from 'react';

import AlreadyLinkedSpan from '../dialogs/AlreadyLinkedSpan';

import LinkIcon from '../../../../../../../common/components/icons/LinkIcon';

import { formatDate } from '../../../../../../../common/utils/DateFunctions';

import global from '../../../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ child }) => (
  <tr>
    <td colSpan="2">{child.firstName || ''}</td>
    <td colSpan="3">{child.organizationName || ''}</td>
  </tr>
);

const DetailTable = ({ personChildren }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th colSpan="2">Child Name</th>
        <th colSpan="3">Organization</th>
      </tr>
    </thead>
    <tbody>
      {personChildren.map((child, i) =>
        <DetailTableRow key={i} child={child} />)
      }
    </tbody>
  </table>
);

const GridRow = ({ personDup, onLinkPersonClicked }) => (
  <Fragment>
    <tr>
      <td>{personDup.personInfo?.firstName || ''} {personDup.personInfo?.lastName || ''}</td>
      <td>{personDup.personInfo?.birthDate ? formatDate(personDup.personInfo.birthDate) : ''}</td>
      <td>{personDup.personInfo?.censoredExistingAccountEmail || ''}</td>
      <td>{personDup.personInfo?.organizationName || ''}</td>
      <td>
        {personDup.isAlreadyLinked === true
          ? (
            <AlreadyLinkedSpan toolTipText={`This person has already been linked to an existing account with the email: ${personDup.personInfo?.censoredExistingAccountEmail}`} />
          ) : (
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onLinkPersonClicked(personDup.personId)}>
              <LinkIcon />
            </button>
          )
        }
      </td>
    </tr>
    {
      Array.isArray(personDup.personInfo?.children) && personDup.personInfo?.children?.length > 0 &&
      <tr className={global.Expanded}>
        <td colSpan="5">
          <DetailTable personChildren={personDup.personInfo.children} />
        </td>
      </tr>
    }
  </Fragment>
);

const PersonDupGridLarge = ({ data, isLoading, onLinkPersonClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Birth Date</th>
        <th>Email</th>
        <th>Organization</th>
        <th>Link Account</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : data.length > 0
          ? data.map((personDup, i) => <GridRow key={i} personDup={personDup} onLinkPersonClicked={onLinkPersonClicked} />)
          : <tr><td colSpan="5">No Duplicate Persons</td></tr>
      }
    </tbody>
  </table>
);

export default PersonDupGridLarge;