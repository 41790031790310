import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const getProfilePhotoByFileUrlData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const fileUrlForUrl = encodeURIComponent(fileUrl);
    const url = `/PersonFiles/profilePhoto?fileUrl=${fileUrlForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const ProfilePhotoData = {
  getProfilePhotoByFileUrlData
};

export default ProfilePhotoData;