import useProfilePic from './UseProfilePic';

import style from './ProfilePic.module.css';

const ProfilePic = ({ size, profile }) => {
  const { fillColor, profilePic } = useProfilePic(profile.initials, profile.profilePicUrl);
  const upperSize = size ? size.toUpperCase() : '';

  if (profilePic) {
    return (
      <div className={upperSize === 'SMALL' ? style.SmallProfilePic : style.ProfilePic}>
        <img src={profilePic} alt="Profile" />
      </div>
    );
  }

  return (
    <div className={upperSize === 'SMALL' ? style.SmallProfilePic : style.ProfilePic}>
      <svg height={upperSize === 'SMALL' ? '50' : '100'} width={upperSize === 'SMALL' ? '50' : '100'}>
        <rect width="100%" height="100%" fill={fillColor} />
        <text x="50%" y="52%" alignmentBaseline="middle" 
          textAnchor="middle" 
          fontSize={upperSize === 'SMALL' ? '16' : '32'} fontFamily="Verdana" 
          fill="#ffffff">
            {profile.initials}
        </text>
      </svg>
    </div>
  );
};

export default ProfilePic;