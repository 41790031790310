import { useState } from 'react';

import ProfilePhotoData from './ProfilePhotoData';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_PROFILE_PHOTO_STATE = {
  ...BASIC_INITIAL_STATE
};

const useProfilePhotoData = () => {
  const [profilePhotoState, setProfilePhotoState] = useState(INITIAL_PROFILE_PHOTO_STATE);

  const getProfilePhotoByFileUrl = (fileUrl) => {
    return ProfilePhotoData.getProfilePhotoByFileUrlData(fileUrl, profilePhotoState, setProfilePhotoState);
  };

  const resetProfilePhotoState = () => {
    setProfilePhotoState({
      ...INITIAL_PROFILE_PHOTO_STATE
    });
  };

  return {
    profilePhotoState,
    setProfilePhotoState,
    getProfilePhotoByFileUrl,
    resetProfilePhotoState
  };
};

export default useProfilePhotoData;