import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import HierarchicalDataUtils from '../../utils/HierarchicalDataUtils';

const GetOrgUnitData = (state, setState, isActive) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const isActiveForUrl = isActive ?? '';
    const url = isActive ? `/OrgUnit/Organization?isActive=${isActiveForUrl}` : `/OrgUnit/Organization`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            treeData: HierarchicalDataUtils.MapArrayToTreeDataIsExpired(arrayData, 'id', 'name', 'isExpired'),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetOrgUnitData;